var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"users","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"sort-by":"id","loading":_vm.loading,"loading-text":"Загружается... Пожалуйста ждите"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("All users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","to":{ name: 'addUser' }}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"font-weight-bold",staticStyle:{"color":"#0288D1"}},[_vm._v(_vm._s(item.name))]),(item.last_name)?_c('b',{staticClass:"font-weight-bold"},[_vm._v(", "+_vm._s(item.last_name))]):_vm._e()]}},{key:"item.lr_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lr_number)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.role === 'client')?_c('v-chip',{attrs:{"color":_vm.getColorRole(item.role),"dark":"","outlined":"","small":""}},[_vm._v("Клиент")]):_vm._e(),(item.role === 'clientroza')?_c('v-chip',{attrs:{"color":_vm.getColorRole(item.role),"dark":"","outlined":"","small":""}},[_vm._v("Клиент Розы")]):_vm._e(),(item.role === 'admin')?_c('v-chip',{attrs:{"color":_vm.getColorRole(item.role),"dark":"","outlined":"","small":""}},[_vm._v("Администратор")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.showUser(item.id)}}},[_vm._v(" mdi-account-circle ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item.id)}}},[_vm._v(" mdi-pencil ")]),(item.deleted_at)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.restoreUser(item)}}},[_vm._v(" mdi-account-reactivate ")]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }